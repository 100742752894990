body{
  font-family: 'Clash Display', sans-serif !important;
  /*font-family: 'Permanent Marker', cursive;
  font-family: 'Radio Canada', sans-serif;*/
  text-transform: uppercase;
}


/*about page*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*body, html {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 300;
  /*overflow: hidden;*/
}
.sucess-message{
  color:#fff;
  text-align: center;
}

p{
  text-transform: initial;
}

.dg.ac {
  z-index: 999 !important;
}

.dribbble {
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
}
.dribbble img {
  display: block;
  height: 28px;
}

.plus img {
	width: 34px;
}

.social-icon{
  display: flex;
}

.twitter {
  position: fixed;
  display: block;
  right: 64px;
  bottom: 14px;
}
.twitter svg {
  width: 32px;
  height: 32px;
  fill: #1da1f2;
}


/*.img-project{
  overflow: hidden;
}*/

.collab-work{
  margin-top: 100px;
}
.img-box-project {
	width: 400px;
	margin: 0 auto;
}

.project:hover{
  transition:  1s ease-in;
  transform: scale(1.1);
}


.line{
  border-top:2px  solid #fff ;
  border-bottom: 2px solid #fff;
  padding-top: 60px;
  padding-bottom: 60px;
}


.link-project {
  text-decoration: none;
  font-size: 3rem;
  color: #fff;
  /*overflow: hidden;*/
  transition: ease .5s all;
  display: block;
  margin: .5rem 0;
  z-index: 2;
  padding: 15px 30px 15px 90px;
  /*text-shadow: 3px 5px 10px rgba(255,255,255,1);*/
}

.link-project:hover h1 {
  transition: ease .5s all;
  padding-left: 1rem;
  color: #ccc;
  text-shadow: 3px 5px 10px rgba(0,0,0,1);
}

.link-project:hover .follow-img {
  opacity: 1;
}

.follow-img {
  opacity: 0;
  width: 100%;
  height: auto;
  max-width: 400px;
  box-sizing: border-box;
  transition: ease .2s;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
}


.img-btn-project{
    border-top:  2px solid #fff;
    border-bottom:  2px solid #fff;
}

/*about end here*/






.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar.navbar-expand-lg.navbar-light.bg-light {
	height: 111px;
  background:transparent !important;
}

.nav-link a{
	color: rgba(0,0,0);
  text-transform: uppercase;
  text-decoration: none !important;
  margin-left:50px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.mg-top {
  margin-top: 20px;
}

.plus {
	color: black;
	font-size: 108px;
	transition: transform .5s ease-in-out;
	width: 52px;
	display: block;
  cursor: pointer;
  text-decoration: unset;
}
.about-link{
  text-decoration: unset;
}
.plus:hover {
  transform: scale(1.1);
  /*transform: rotate(360deg);
  transform-origin: center;*/
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-row {
	padding: unset;
	
}

.img-profile{
  margin-top:50px;
}

span{
  color: white;
}

.name{
  font-size: 72px;
  position: relative;
  text-transform: uppercase;
}

.description{
  margin-top:230px;
}


.btn-project01,.btn-project02,.btn-project03,.btn-project04,.timeline{
  color: rgb(0, 0, 0);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left:100px;
  border-bottom: 2px solid #000;
  cursor: pointer;
}





.navbar-brand a{
  color:#000;
  text-decoration: none;
}


.timeline-container{
    margin-top:50px;
}


.timeline{
  background-color:rgb(0, 0, 0);
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left:100px;
  border-bottom: 2px solid white;
  cursor: pointer;
}

.timeline-list{
  background-color:rgb(0, 0, 0);
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid white;
  cursor: pointer;
  font-size: 19px;
}



.timeline:hover{
  background-color:rgb(0, 0, 255);
  cursor: pointer;
}



/*.project-01,.project-02,.project-03,.project-04{
  width:200px;
  height:200px;
  margin-right: 40px;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom:0;
}*/






.work{
  position:absolute;
  right:0;
  margin-right:50px;
}

.span-desc-01{
  margin-left: 40%;
}

.span-desc-02{
  margin-left: 45.5%;
}

.btn-project-col{
  margin-top: 100px !important;
  padding:0 !important;
}

h1{
  font-weight: 900;
}

.title{
  color:white;
  text-transform: uppercase;
  font-weight: 300;
  font-size:100px;
  position: relative;
  top:100px;
  right:100px;
  line-height: 1;
}

.who-is-me{
  color:white;
  font-size: 24px;
}
.description-02{
  color:white;
  font-size:18px;
  margin-top:20px;
}

.timeline{
  text-transform: uppercase;
  font-size:36px;
}

.section{
  color:white;
  border-top:1px solid #fff;
  border-bottom:1px solid #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}

.border-left{
  border-left:1px solid #fff;
  border-bottom:1px solid #fff;
  /*overflow:hidden;*/
}

.section-heading{
  font-weight: 100;
  font-size:74px;
  text-transform: uppercase;
}


.title-project{
  color: #fff;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
.clients{
  font-weight: 100;
  position: absolute;
}

.project-tip {
	color: #fff;
	margin-top: 20px;
	/*position: absolute;*/
}
.project-img {
  width:80%;
  display:block;
  margin: 0 auto;
  padding-top: 10px;
}

.desc-section{
  color: white;
  margin-top: 30px;
  margin-bottom: 30px;
}

.block{
  width:100%;
  height:200px;
  background-color: #fff;
}

.video{
  width:70%;
  display: block;
  margin: 0 auto;
  margin-top:50px;
  margin-bottom:50px;
}

form{
  width:50%;
  margin: 0 auto;
  margin-top:60px;
}

div input{
  margin-bottom:30px;
}

input,textarea{
  width:100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #fff;
  outline: none;
  background-color:transparent;
  color:lightgrey;
}

label{
  color:white;
}

input[type="submit"]{
 margin-top:30px;
 color:white;
 padding:10px;
 border: 2px solid #fff;
}

input[type="submit"]:hover{
  margin-top:30px;
  color:#000;
  padding:10px;
  border: 2px solid #fff;
  background-color:#fff;
 }


 .main-img {
	width: 100%;
	position: relative;
	top: -154px;
}

.project-img{
  margin-top:20px;
}

a {
	color: #5477aa;
	text-decoration: none;
}


.service{
  border-top: 1px solid #000;
  border-bottom:1px solid #000;
  padding-top:50px;
  padding-bottom: 50px;
  margin-top: 200px;
}

.title-description{
  padding-top:20px;
  padding-bottom:20px;
}

.short-description{
  padding-top:20px;
  padding-bottom:20px;
  border-bottom: 1px solid #000;
}

.top-desc{
  border-top: 1px solid #000;
}

.skills{
  color: #fff;
}

.col-md-2.title-description {
	font-size: 19px;
}

.col-md-6.short-description.top-desc {
	font-size: 19px;
}

.container.skills {
	margin-top: 80px;
}

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.container {
  width: 100%;
  height: 100%;
  /*background: rgba(255, 255, 255, 0.2);*/
}

.item {
  width: inherit;
  height: inherit;
  background: rgb(0, 0, 0);
  transform-origin: 50% 100%;
}

.about-me{
  transition: 2s ease-in-out;
  animation-name: anim-top;
  animation-duration:1s;
  animation-delay: 1s;
}

.home-page,.margin-top{
  margin-top: 100px;
}
@keyframes anim-top{
   /*from {opacity: 0%;}
   to{opacity: 100%;}*/

   from {transform: translateX(-100px)}
   to{transform: translateX(1000px)}
   
}

a {
	color: #0d6efd;
	text-decoration: unset;
}

.title-project-inside{
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding:15px 0px;
  color:#fff;
}

.unique{
  margin-top: 30px;
}


.last-img,.larts-05{
  margin-top: 250px;
}

.m-top{
  margin-top: 400px;
}
.m-top-01{
  margin-top: 100px;
}

.m-top-02{
  margin-top: 300px;
}

.projects-list{
  color:#fff;
}

.projects-list div{
  color:#fff;
}


.projects-list div{
  border-bottom:2px solid #fff;
}


.mobile-me{
  display: none;
}


/*responsive*/



@media (min-width: 1920px){
 
}


@media (min-width: 1600px){
  
}


@media (min-width: 1366px){
 
}

@media(max-width:768px){
  .work {
    position:none;
    margin-right: 50px;
  }

  .project:hover {
    transition: 1s ease-in;
    transform: none;
  }

  .img-box-project {
    width: 314px;
    margin: 0 auto;
  }

  .title {
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 41px;
    position: unset;
    top: 100px;
    right: 100px;
    line-height: 1;
  }

  .img-fluid {
    max-width: 74%;
    height: auto;
    margin: 0 auto;
  }

  .name {
    font-size: 58px;
    position: relative;
    text-transform: uppercase;
  }

  .mobile-me {
    display: block;
    width: 230px;
    margin: 0 auto;
    margin-top: 80px;
  }

  .desktop-me{
    display: none;
  }

  .fixed-top {
position: unset !important;
    
  }

  .description {
    margin-top: 102px;
  }
}

@media(max-width:768px){
  .work {
    position:unset;
    margin-right: initial;
  }
}




